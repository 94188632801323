<template>
  <div class="main">
    <!-- 隱藏先
    <div class="txt-r">
      <span class="vam">{{$t('Sort')}}：</span>
      <el-select class="select vam" v-model="sort" size="small" placeholder="默認">
        <el-option v-for="item in sortOptions"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
    </div>
    -->
    <div class="list cl">
      <theme-travel-item v-for="(item,index) of list"
                         :datas="item"
                         :index="index"
                         :list.sync="list"
                         is-collection
                         :key="item.productId"/>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
  import ThemeTravelItem from '../../components/ThemeTravelItem';
  import Empty from '../../components/Empty';
  import {getCollectionList} from '../../api/collection';

  export default {
    name: 'MyCollection',
    data() {
      return {
        sortOptions: [
          {value: '1', label: '默認'},
          {value: '2', label: '最新行程'}
        ],
        sort: '',
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    components: {Empty, ThemeTravelItem},
    created() {
      this.getList();
    },
    methods: {
      // 获取收藏列表
      getList(page = 1) {
        const data = {page, perPage: 10};
        getCollectionList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      }
    },
    watch: {
      list: function (value) {
        this.isEmpty = this.totalPages <= 1 && !value.length;
      }
    }
  };
</script>
<style scoped lang="less">
  .select{width:220px;}
  .list{
    margin-top:20px;
    /deep/ .theme-item{width:210px;}
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
